<template>
  <div>
    <h4 class="font-weight-bold pb-1 primary--text">Gestión</h4>
    <h5 class="font-weight-light pb-3">Gestión de Proyectos</h5>

    <v-card class="elevation-15" id="cardborde">
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="verde"
      ></v-progress-linear>
      <v-card-title class="font-weight-light">
        Seguimiento de Proyectos
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          color="primary"
          label="Buscar"
          hide-details
          class="m-2"
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-tabs right>
        <v-tab @click="bEstado = 'Pendiente'">Activo</v-tab>
        <v-tab @click="bEstado = 'Realizado'">Finalizados</v-tab>
        <v-tab @click="bEstado = 'Todos'">Todos</v-tab>
      </v-tabs>
      <v-data-table
        class="mt-3"
        :headers="headers"
        :items="filteredItems"
        :no-results-text="noresult"
        no-data-text="Sin resultados que mostrar"
        :search="search"
        :custom-filter="filterOnlyCapsText"
        :sort-by="['fecha_estimada_realizacion']"
        :sort-desc="[true]"
        :custom-sort="customSort"
        :footer-props="{
          'items-per-page-text': 'Resultados por página',
          'items-per-page-all-text': 'Todos',
        }"
      >
        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:item.url="{ item }">
          <v-icon small class="mr-2" @click="verURL(item.url)"> mdi-web </v-icon>
        </template>
        <template v-slot:item.nro_proyecto="{ item }">
          <a
            href="#"
            class="azulElectrico--text"
            style="text-decoration: underline; color: inherit;"
            @click="
              abreDetalleProyecto(
                item._id,
                item.mandante,
                item.responsableCompleto,
                item.nro_proyecto,
                item.nombre
              )
            "
            ><b>{{ item.nro_proyecto }}</b></a>
        </template>
        <template v-slot:item.mandante="{ item }">

          <div v-if="item.obras">
            <div v-if="item.mandante">
              <v-chip class="ma-0 p-1" dark x-small label  color="green"><b>M</b></v-chip> {{ item.mandante }}
            </div>
            <div v-else>
              <v-chip class="ma-0 p-1" dark x-small label  color="green"><b>M</b></v-chip> Sin mandante
              
            </div>
            <v-chip  class="ma-0 p-1" dark x-small label  color="brown"><b>O</b></v-chip>
            {{ item.obra.nombre }}
            
          </div>
          <div v-else>
            <!----<v-chip class="ma-0 p-1" dark x-small label  color="green"><b>M</b></v-chip>--> 
            {{ item.mandante }}
          </div>

          
        </template>
        <template v-slot:item.accion="{ item }">

          <v-icon
            class="mr-2 blue--text text--darken-5"
            v-if="item.tipoReg == 2"
            @click="
              editarEvento(item._id, item.concepto, item.fecha_estimada_realizacion)
            "
          >
            mdi-pencil
          </v-icon>
          <v-chip
              class="mr-2 p-1"
              color="primary"
              text-color="white"
              small
              v-if="item.status == 'Pendiente'"
            >
            <v-icon
              small
              v-if="item.status == 'Pendiente' && item.tipoReg == 2"
              @click="abreDialogRegistroAccionEvento(item._id)"
            >
              mdi-calendar-check
            </v-icon>
            <v-icon
              small
              v-else-if="item.status == 'Pendiente' && item.tipoReg == 1"
              @click="abreDialogRegistroAccion(item._id, item.id_concepto)"
            >
              mdi-calendar-check
            </v-icon>
          </v-chip>

          <v-chip
              class="mr-2 p-1"
              color="primary"
              text-color="verde"
              small
            >
            <v-icon
              small
              @click="abreDialogSubirDocumento(item._id, item.id_concepto)"
            >
              mdi-cloud-upload
            </v-icon>
          </v-chip>
        </template>

        <template v-slot:item.status="{ item }">
          <!--<v-chip :color="getEstados(item.estado)" dark outlined>{{ getEstadosTexto(item.estado) }}</v-chip>-->
          <v-chip class="ma-2" dark small label outlined :color="getEstados(item.status)">
            <b>{{ item.status }}</b>
          </v-chip>
        </template>
        <template v-slot:item.responsable="{ item }">
          <!--<v-chip :color="getEstados(item.estado)" dark outlined>{{ getEstadosTexto(item.estado) }}</v-chip>-->
          <v-chip
            class="ma-2"
            dark
            small
            :color="item.color"
            data-toggle="tooltip"
            data-placement="bottom"
            :title="item.responsableCompleto"
          >
            {{ item.responsable }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar v-model="snackbar" color="green" dark timeout="1500" align="center">
      URL copiada exitosamente!
    </v-snackbar>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogRegistroAccion"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="primary white--text"> Registro </v-card-title>
          <v-card-text class="mt-4">
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Fecha Realizado"
                  v-model="fecha_realizacion"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  required
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fecha_realizacion"
                locale="es-es"
                @input="menu3 = false"
                color="verde primary--text"
              ></v-date-picker>
            </v-menu>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="
                dialogRegistroAccion = false;
                fecha_realizacion = '';
              "
              class="mb-2 indigo lighten-5 primary--text"
              >Cerrar</v-btn
            >
            <v-spacer></v-spacer>

            <v-btn
              text
              class="mb-2 indigo--text text--lighten-5 primary"
              @click="registrarVisita()"
              >Registrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogRegistroAccionEvento"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="primary white--text">
            Registro 
          </v-card-title>
          <v-card-text class="mt-4">
            <v-menu
              v-model="menu8"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Fecha Realizado"
                  v-model="evento_fecha_realizacion"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  required
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="evento_fecha_realizacion"
                locale="es-es"
                @input="menu8 = false"
                color="verde primary--text"
              ></v-date-picker>
            </v-menu>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="
                dialogRegistroAccionEvento = false;
                evento_fecha_realizacion = '';
              "
              class="mb-2 indigo lighten-5 primary--text"
              >Cerrar</v-btn
            >
            <v-spacer></v-spacer>

            <v-btn
              text
              class="mb-2 indigo--text text--lighten-5 primary"
              @click="registrarVisitaEvento()"
              >Registrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogNuevoDocumento"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="primary white--text"> Nuevo documento </v-card-title>
          <v-card-text class="mt-4">
            <v-text-field
              label="Nombre del documento"
              v-model="nombreArchivo"
              outlined
              hide-details
            >
            </v-text-field>
          </v-card-text>
          <v-card-text class="mt-4">
            <v-file-input
              v-model="archivotemp"
              hide-details
              label="Documento"
              type="file"
              ref="file"
              @change="selectFile"
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialogNuevoDocumento = false"
              class="mb-2 indigo lighten-5 primary--text"
              >Cerrar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              class="mb-2 indigo--text text--lighten-5 grey"
              disabled
              v-if="files == null"
              >Subir documento</v-btn
            >
            <v-btn
              text
              class="mb-2 indigo--text text--lighten-5 primary"
              @click="subirDocumento()"
              v-else
              >Subir documento</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogRegistroEvento"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="primary white--text">
            Registro Actividad </v-card-title
          >
          <v-card-text class="mt-4">
            <v-text-field
              label="Nombre Actividad"
              v-model="evento_actividad"
              outlined
              hide-details
              :rules="basic_validation_rules_input"
              required
            >
            </v-text-field>
          </v-card-text>
          <v-card-text>
            <v-textarea
              outlined
              label="Notas de la actividad"
              v-model="evento_detalle"
              hide-details
            ></v-textarea>
          </v-card-text>
          <v-card-text class="mt-4">
            <v-menu
              v-model="menu6"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Fecha estimada"
                  v-model="evento_fecha_evento"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  required
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="evento_fecha_evento"
                locale="es-es"
                @input="menu6 = false"
                color="verde primary--text"
              ></v-date-picker>
            </v-menu>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="
                dialogRegistroEvento = false;
                evento_fecha_evento = '';
                evento_actividad = '';
                evento_detalle = '';
              "
              class="mb-2 indigo lighten-5 primary--text"
              >Cerrar</v-btn
            >
            <v-btn
              text
              @click="eliminarEvento()"
              v-if="tipoRegistroEvento === 1"
              class="mb-2 red lighten-5 primary--text"
              >Eliminar evento</v-btn
            >
            <v-spacer></v-spacer>

            <v-btn
              text
              class="mb-2 indigo--text text--lighten-5 primary"
              @click="registrarEvento()"
              >Registrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="dialogEvento" scrollable max-width="900px">
      <v-card>
        <v-card-title class="primary--text">
          Eventos del proyecto
          <v-spacer></v-spacer>
          <v-btn
            class="mb-2 verde primary--text"
            @click="nuevoEvento()"
          >
            Nuevo evento
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="2" class="primary--text"> <b> Nº Proyecto: </b> </v-col>
            <v-col cols="12" lg="3">{{ evento_detalle_nro_proyecto }} </v-col>
            <v-col cols="12" lg="2" class="primary--text"> <b> Nombre Proyecto: </b> </v-col>
            <v-col cols="12" lg="5">{{ evento_detalle_nombre }} </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="2" class="primary--text"> <b> Mandante: </b> </v-col>
            <v-col cols="12" lg="3">
              {{ evento_detalle_mandante }}
            </v-col>
            <v-col cols="12" lg="2" class="primary--text"> <b> Responsable: </b> </v-col>
            <v-col cols="12" lg="3">{{ evento_detalle_responsable }} </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <v-data-table
            class="mt-3"
            :headers="headers_eventos"
            :items="filteredItems_eventos"
            :no-results-text="noresult"
            no-data-text="Sin resultados que mostrar"
            :search="search"
            :custom-filter="filterOnlyCapsText"
            :sort-by="['fecha_estimada_realizacion']"
            :sort-desc="[true]"
            :custom-sort="customSort"
            :footer-props="{
              'items-per-page-text': 'Resultados por página',
              'items-per-page-all-text': 'Todos',
            }"
          >
            <template #footer.page-text="props">
              {{ props.pageStart }}-{{ props.pageStop }} de
              {{ props.itemsLength }}
            </template>
            <template v-slot:item.url="{ item }">
              <v-icon small class="mr-2" @click="verURL(item.url)"> mdi-web </v-icon>
            </template>
            <template v-slot:item.detalle="{ item }">
              <span style="white-space: pre">{{ item.detalle }}</span>
            </template>
            <template v-slot:item.accion="{ item }">
              <v-chip
                class="mr-2 p-1"
                color="primary"
                text-color="verde"
                small
                v-if="item.tipoReg == 2"
              >
                <v-icon
                  small
                  @click="
                    editarEvento(
                      item.concepto_id,
                      item.concepto,
                      item.fecha_estimada_realizacion,
                      item.detalle
                    )
                  "
                >
                  mdi-pencil
                </v-icon>
              </v-chip>
              <v-chip
                class="mr-2 p-1"
                color="primary"
                text-color="white"
                small
                v-if="item.status == 'Pendiente'"
              >
                <v-icon
                  small
                  v-if="item.status == 'Pendiente' && item.tipoReg == 2"
                  @click="abreDialogRegistroAccionEvento(item.concepto_id)"
                >
                  mdi-calendar-check
                </v-icon>
                <v-icon
                  small
                  v-else-if="item.status == 'Pendiente' && item.tipoReg == 1"
                  @click="abreDialogRegistroAccion(item._id, item.id_concepto)"
                >
                  mdi-calendar-check
                </v-icon>
              </v-chip>
            </template>

            <template v-slot:item.status="{ item }">
              <!--<v-chip :color="getEstados(item.estado)" dark outlined>{{ getEstadosTexto(item.estado) }}</v-chip>-->
              <v-chip class="ma-2" dark small label outlined :color="getEstados(item.status)">
                <b>{{ item.status }}</b>
              </v-chip>
            </template>
            <template v-slot:item.responsable="{ item }">
              <!--<v-chip :color="getEstados(item.estado)" dark outlined>{{ getEstadosTexto(item.estado) }}</v-chip>-->
              <v-chip
                class="ma-2"
                dark
                small
                :color="item.color"
                data-toggle="tooltip"
                data-placement="bottom"
                :title="item.responsableCompleto"
              >
                {{ item.responsable }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            @click="dialogEvento = false"
            class="mb-2 verde primary--text"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

//Definimos la clase de los elementos

export default {
  data() {
    return {
      idProyectoAbierto: "",
      dialogEventos: false,
      dialogEvento: false,
      dialogRegistroAccion: false,
      tipoRegistroEvento: 0,
      fecha_realizacion: "",
      menu3: false,
      menu8: false,
      loading: true,
      noresult: "No existen resultados",
      search: "",
      dialogVerURL: false,
      urlSitio: "",
      snackbar: false,
      headers: [
        {
          text: "Nº Pyto",
          align: "start",
          sortable: true,
          value: "nro_proyecto",
          width: "15%",
        },

        {
          text: "Nombre",
          value: "nombre",
          width: "20%",
        },
        {
          text: "Mandante",
          value: "mandante",
          width: "20%",
        },
        {
          text: "Resp.",
          value: "responsable",
          width: "1%",
        },
        {
          text: "Concepto",
          align: "start",
          sortable: false,
          value: "concepto",
          width: "20%",
        },
        {
          text: "Fecha Est.",
          align: "start",
          sortable: true,
          value: "fecha_estimada_realizacion",
        },

        {
          text: "Estado",
          value: "status",
        },

        {
          text: "Accion",
          value: "accion",
          sortable: "false",
          width: "15%",
        },
      ],
      desserts: [],
      bEstado: "Pendiente",
      tipo: [
        {
          tipo: "1",
          text: "Proyectos",
        },
        {
          tipo: "2",
          text: "ITO",
        },
      ],
      tipos_moneda: [
        {
          tipo: 1,
          text: "U.F",
        },
        {
          tipo: 2,
          text: "Pesos Chilenos",
        },
      ],
      estados: [
        {
          tipo: 1,
          text: "Pendiente",
        },
        {
          tipo: 2,
          text: "Aprobado",
        },
        {
          tipo: 3,
          text: "Rechazado",
        },
      ],
      estados_proyecto: [
        {
          tipo: 1,
          text: "Activo",
        },
        {
          tipo: 2,
          text: "Congelado",
        },
        {
          tipo: 3,
          text: "Finalizado",
        },
      ],
      estados_concepto: [
        {
          tipo: 1,
          text: "Pendiente",
        },
        {
          tipo: 2,
          text: "Realizado",
        },
        {
          tipo: 3,
          text: "Facturado",
        },
      ],
      idProyecto: "",
      idConcepto: "",
      idProyectoSubirDocumento: "",
      dialogNuevoDocumento: false,
      files: null,
      nombreArchivo: "",
      archivotemp: null,

      evento_actividad: "",
      evento_detalle: "",
      evento_fecha_evento: "",
      evento_id: "",
      evento_id_proyecto: "",
      dialogRegistroEvento: false,
      menu6: false,
      evento_fecha_realizacion: "",
      idEvento: "",
      dialogRegistroAccionEvento: false,

      headers_eventos: [
        {
          text: "Evento",
          align: "start",
          sortable: true,
          value: "concepto",
          width: "20%",
        },
        {
          text: "Detalle evento",
          align: "start",
          sortable: true,
          value: "detalle",
          width: "35%",
        },
        {
          text: "Fecha Est.",
          align: "start",
          sortable: true,
          value: "fecha_estimada_realizacion",
          width: "15%",
        },

        {
          text: "Estado",
          value: "status",
          width: "10%",
        },

        {
          text: "Accion",
          value: "accion",
          sortable: "false",
          width: "20%",
        },
      ],
      evento_detalle_mandante: "",
      evento_detalle_responsable: "",
      evento_detalle_nro_proyecto: "",
      evento_detalle_nombre: "",
    };
  },

  computed: {
    ...mapState(["token", "notificationSystem", "usuarioDB"]),
    filteredItems() {
      if (this.bEstado == "Todos") {
        return this.desserts.filter((i) => {
          return i.tipoReg == 1;
        });
        //return this.desserts;
      } else {
        if (this.bEstado == "Realizado") {
          return this.desserts.filter((i) => {
            return (
              i.tipoReg == 1 && (i.status === this.bEstado || i.status === "Facturado")
            );
          });
        } else {
          return this.desserts.filter((i) => {
            return i.status === this.bEstado && i.tipoReg == 1;
          });
        }
      }

      //return this.desserts;
    },
    filteredItems_eventos() {
      return this.desserts.filter((i) => {
        return i._id === this.idProyectoAbierto && i.tipoReg == 2;
      });
      //return this.desserts_eventos;
    },
    formTitleEventos() {
      return this.editedIndexEventos === -1 ? "Nuevo Evento" : "Editar Evento";
    },
  },
  methods: {
    abreDetalleProyecto(id, mandante, responsableCompleto, nro_proyecto, nombre) {
      this.dialogEvento = true;
      this.idProyectoAbierto = id;
      this.evento_detalle_mandante = mandante;
      this.evento_detalle_responsable = responsableCompleto;
      this.evento_detalle_nro_proyecto = nro_proyecto;
      this.evento_detalle_nombre = nombre;
    },
    editItem(id) {
      this.$router.push({
        name: "proyecto",
        params: {
          id: id,
        },
      });
    },
    nuevoEvento() {
      this.evento_actividad = "";
      this.evento_detalle = "";
      this.evento_fecha_evento = "";
      this.dialogRegistroEvento = true;
      this.tipoRegistroEvento = 0;
      this.evento_id = "";
      this.evento_id_proyecto = "";
    },
    editarEvento(id, evento_actividad, evento_fecha_evento, evento_detalle) {
      this.evento_actividad = evento_actividad;
      this.evento_detalle = evento_detalle;
      this.evento_fecha_evento = evento_fecha_evento
        ? this.$options.filters.fecha_gringa(evento_fecha_evento)
        : "";
      this.dialogRegistroEvento = true;
      this.tipoRegistroEvento = 1;
      this.evento_id = id;
      this.evento_id_proyecto = this.idProyectoAbierto;
    },
    abreDialogRegistroAccion(idProyecto, idConcepto) {
      this.dialogRegistroAccion = true;
      this.idProyecto = idProyecto;
      this.idConcepto = idConcepto;
    },
    abreDialogRegistroAccionEvento(idEvento) {
      this.dialogRegistroAccionEvento = true;
      this.idEvento = idEvento;
    },
    abreDialogSubirDocumento(idProyecto, idConcepto) {
      this.dialogNuevoDocumento = true;
      this.idProyectoSubirDocumento = idProyecto;
    },
    selectFile(file) {
      this.files = file;
    },
    async subirDocumento() {
      this.loading = true;

      let config = {
        headers: {
          token: this.token,
          "Content-Type": "multipart/form-data;",
        },
      };

      const formData = new FormData();
      formData.append("file", this.files);
      formData.append("nombre", this.nombreArchivo);
      formData.append("idProyecto", this.idProyectoSubirDocumento);

      await axios
        .put("/proyectos/subirDocumento/", formData, config)
        .then((response) => {
          this.loading = false;
          this.files = null;
          this.nombreArchivo = "";
          this.archivotemp = null;
          this.dialogNuevoDocumento = false;
          this.idProyectoSubirDocumento = "";
          //this.getData();
          this.$toast.success(
            "Grabado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    registrarVisita() {
      let config = {
        headers: {
          token: this.token,
        },
      };

      axios
        .put(
          "/proyectos/registrarVisita/",
          {
            idProyecto: this.idProyecto,
            idConcepto: this.idConcepto,
            fecha: this.fecha_realizacion,
          },
          config
        )
        .then((response) => {
          this.fecha_realizacion = "";
          this.dialogRegistroAccion = false;
          this.idProyecto = "";
          this.idConcepto = "";
          this.actualizaDatas();
          //this.getDataEventos();
          this.$toast.success(
            "Grabado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
      //console.log(this.datosFormulario);
    },
    registrarEvento() {
      let config = {
        headers: {
          token: this.token,
        },
      };

      if (this.tipoRegistroEvento === 1) {
        axios
          .put(
            "/proyectos/subirEvento/" + this.evento_id_proyecto + "/" + this.evento_id,
            {
              evento: this.evento_actividad,
              detalle: this.evento_detalle,
              fecha_estimada_realizacion: this.evento_fecha_evento,
            },
            config
          )
          .then((response) => {
            this.evento_actividad = "";
            this.evento_detalle = "";
            this.dialogRegistroEvento = false;
            this.evento_fecha_evento = "";
            this.actualizaDatas();
            //this.getDataEventos();
            this.$toast.success(
              "Evento modificado con exito!",
              "",
              this.notificationSystem.options.error
            );
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.error(
              "Ocurrio un error de sistema, vuelva a intentarlo",
              "Error",
              this.notificationSystem.options.error
            );
          });
      } else {
        axios
          .post(
            "/proyectos/subirEvento/" + this.idProyectoAbierto,
            {
              evento: this.evento_actividad,
              detalle: this.evento_detalle,
              fecha_estimada_realizacion: this.evento_fecha_evento,
              fecha_realizacion: null,
              fecha_registro_realizacion: null,
              estado: 1,
            },
            config
          )
          .then((response) => {
            this.evento_actividad = "";
            this.evento_detalle = "";
            this.dialogRegistroEvento = false;
            this.evento_fecha_evento = "";
            this.actualizaDatas();
            //this.getDataEventos();
            this.$toast.success(
              "Grabado con exito!",
              "",
              this.notificationSystem.options.error
            );
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.error(
              "Ocurrio un error de sistema, vuelva a intentarlo",
              "Error",
              this.notificationSystem.options.error
            );
          });
      }

      //console.log(this.datosFormulario);
    },
    eliminarEvento() {
      let config = {
        headers: {
          token: this.token,
        },
      };

      axios
        .delete(
          "/proyectos/eliminarEvento/" + this.evento_id + "/" + this.idProyectoAbierto,
          config
        )
        .then((response) => {
          this.evento_actividad = "";
          this.dialogRegistroEvento = false;
          this.evento_fecha_evento = "";
          this.evento_id = "";
          this.actualizaDatas();
          //this.getDataEventos();
          this.$toast.success(
            "Evento modificado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });

      //console.log(this.datosFormulario);
    },
    registrarVisitaEvento() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      console.log();

      axios
        .put(
          "/proyectos/registrarVisitaEvento/",
          {
            idEvento: this.idEvento,
            idProyecto: this.idProyectoAbierto,
            fecha: this.evento_fecha_realizacion,
          },
          config
        )
        .then((response) => {
          this.evento_fecha_realizacion = "";
          this.dialogRegistroAccionEvento = false;
          this.idEvento = "";
          this.actualizaDatas();
          //this.getDataEventos();
          this.$toast.success(
            "Grabado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
      //console.log(this.datosFormulario);
    },
    async getData() {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            token: this.token,
          },
        };
        let filtro = this.$options.filters;

        axios.get("/proyectos/seguimiento/", config).then((response) => {
          console.log(response);
          if(response.data.length > 0){
          var dataResponse = response.data;
          var responsefinal = [];
          for (let dato in dataResponse) {
            for (let concepto of dataResponse[dato].condiciones) {
              let fRealizacion = null;
              if (concepto.fecha_estimada_realizacion) {
                fRealizacion = filtro.fecha_normal(concepto.fecha_estimada_realizacion);
              }

              let nombreMandante = "";
            //if (pres.mandante.nombre) {
              if(dataResponse[dato].presupuestos[0].mandante){
                    nombreMandante = dataResponse[dato].presupuestos[0].mandante.nombre;
                  }else{
                    nombreMandante = "Sin mandante"
                  
              }
              responsefinal.push({
                _id: dataResponse[dato]._id,
                tipoReg: 1,
                nombre: dataResponse[dato].nombre,
                nro_proyecto: dataResponse[dato].nro_proyecto,

                mandante: nombreMandante,
                obras: dataResponse[dato].presupuestos[0].obras,
                obra: dataResponse[dato].presupuestos[0].obra,
                responsable: filtro.sigla(dataResponse[dato].encargado.nombre),
                responsableCompleto: dataResponse[dato].encargado.nombre,
                color: dataResponse[dato].encargado.color,
                concepto: concepto.concepto,
                id_concepto: concepto._id,
                fecha_estimada_realizacion: fRealizacion,
                status: filtro.filtro_status(concepto.estado, this.estados_concepto),
              });
            }

            for (let evento of dataResponse[dato].eventos) {
              let fRealizacion = null;
              if (evento.fecha_estimada_realizacion) {
                fRealizacion = filtro.fecha_normal(evento.fecha_estimada_realizacion);
              }
              let nombreMandante = "";
              if(dataResponse[dato].presupuestos[0].mandante){
                    nombreMandante = dataResponse[dato].presupuestos[0].mandante.nombre;
                  }else{
                    nombreMandante = "Sin mandante"
                  
              }

              responsefinal.push({
                _id: dataResponse[dato]._id,
                tipoReg: 2,
                nombre: dataResponse[dato].nombre,
                nro_proyecto: dataResponse[dato].nro_proyecto,

                mandante: nombreMandante,
                responsable: filtro.sigla(dataResponse[dato].encargado.nombre),
                responsableCompleto: dataResponse[dato].encargado.nombre,
                color: dataResponse[dato].encargado.color,
                concepto: evento.evento,
                concepto_id: evento._id,
                detalle: evento.detalle,
                id_concepto: evento._id,
                fecha_estimada_realizacion: fRealizacion,
                status: filtro.filtro_status(evento.estado, this.estados_concepto),
              });
            }
          }
          this.desserts = responsefinal;
          }
          resolve("ok");

          this.loading = false;
        });
      });
    },
    async getDataEventos() {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            token: this.token,
          },
        };
        let filtro = this.$options.filters;

        axios.get("/eventos/evento/", config).then((response) => {
          //console.log(response.data);
          var dataResponse = response.data;
          var responsefinal = [];
          for (let dato in dataResponse) {
            let fRealizacion = null;
            if (dataResponse[dato].fecha_evento) {
              fRealizacion = filtro.fecha_normal(dataResponse[dato].fecha_evento);
            }
            /*this.desserts.push({
              _id: dataResponse[dato]._id,
              tipoReg: 2,
              nombre: "",
              nro_proyecto: "",
              mandante: "",
              concepto: dataResponse[dato].actividad,
              fecha_estimada_realizacion: fRealizacion,
              responsable: filtro.sigla(dataResponse[dato].responsable.nombre),
              responsableCompleto: dataResponse[dato].responsable.nombre,
              color: dataResponse[dato].responsable.color,
              status: filtro.filtro_status(
                dataResponse[dato].estado,
                this.estados_concepto
              ),

            });*/
            //}
          }
          //console.log(responsefinal);
          //this.desserts.push(responsefinal);
          resolve("ok");

          this.loading = false;
        });
      });
    },
    async deleteItem(item) {
      this.loading = true;
      let config = {
        headers: {
          token: this.token,
        },
      };

      await axios
        .delete("/presupuestos/presupuesto/" + item, config)
        .then((response) => {
          this.actualizaDatas();
          //this.getDataEventos();
          this.$toast.success(
            "Eliminado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    getEstados(estado) {
      if (estado == "Pendiente") return "orange";
      else if (estado == "Realizado") return "light-green darken-2";
      else if (estado == "Facturado") return "deep-purple lighten-2";
    },

    getEstadosTexto(estado) {
      if (estado == true) return "check";
      else if (estado == false) return "close";
      else return "N/A";
    },

    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
    async actualizaDatas() {
      let getD = await this.getData();
      let getEvents = await this.getDataEventos();
    },
    customSort(items, index, isDesc) {
      //console.log(index);
      let items2 = items.filter((e) => e.fecha_estimada_realizacion);

      let comparador = (fecha1, fecha2, desc) => {
        if (!fecha1) {
          return 1;
        }
        if (!fecha2) {
          return -1;
        }

        let fecha1Splitted = fecha1.split("/");
        let fecha2Splitted = fecha2.split("/");
        let fecha11 =
          fecha1Splitted[2] + "-" + fecha1Splitted[1] + "-" + fecha1Splitted[0];
        let fecha22 =
          fecha2Splitted[2] + "-" + fecha2Splitted[1] + "-" + fecha2Splitted[0];
        let date1 = new Date(fecha11);
        let date2 = new Date(fecha22);
        return date1 < date2 ? 1 : -1;
      };

      items.sort((a, b) => {
        //console.log(index);
        if (index[0] === "fecha_estimada_realizacion") {
          if (!isDesc[0]) {
            return comparador(
              a.fecha_estimada_realizacion,
              b.fecha_estimada_realizacion,
              2
            );
          } else {
            return comparador(
              b.fecha_estimada_realizacion,
              a.fecha_estimada_realizacion,
              1
            );
          }
        } else if (index[0] === "nro_proyecto") {
          //console.log(a);
          let nro_splitted = a.nro_proyecto.split("-");
          let va = a.nro_proyecto;
          if (nro_splitted.length >= 2) {
            va = parseInt(nro_splitted[1] + "" + nro_splitted[2]);
          }

          let nro_splittedb = b.nro_proyecto.split("-");
          let vb = b.nro_proyecto;
          if (nro_splittedb.length >= 2) {
            vb = parseInt(nro_splittedb[1] + "" + nro_splittedb[2]);
          }
          if (!isDesc[0]) {
            if (va < vb) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (vb < va) {
              return 1;
            } else {
              return -1;
            }
          }
        } else {
          if (!isDesc) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
  },
  async created() {
    let getD = await this.getData();
    let getEvents = await this.getDataEventos();
    //console.log(this.desserts);
  },
  filters: {
    filtro_tipos: function (valor, tipos) {
      if (tipos.filter((res) => res.tipo == valor).length > 0) {
        return tipos.filter((res) => res.tipo == valor)[0].text;
      } else {
        return "";
      }
    },
    filtro_status: function (valor, tipos) {
      if (tipos.filter((res) => res.tipo == valor).length > 0) {
        return tipos.filter((res) => res.tipo == valor)[0].text;
      } else {
        return "";
      }
    },
    filtro_valor: function (valor, tipo_moneda) {
      if (tipo_moneda == "1") {
        return `U.F ${valor}`;
      } else {
        return `$ ${valor}`;
      }
    },
    fecha_normal: function (valor) {
      return moment(valor, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    fecha_gringa: function (valor) {
      //let splitted = valor.split('-');

      return moment(valor, "DD/MM/YYYY").format("YYYY-MM-DD");
    },

    formato_numero(valor) {
      return new Intl.NumberFormat("de-DE").format(Math.round(valor).toFixed(0));
    },
    sigla(valor) {
      let splitted = valor.split(" ");
      let ret = "";
      for (let i = 0; i < splitted.length; i++) {
        ret = ret + splitted[i][0];
      }

      return ret.toLocaleUpperCase();
    },
  },
};
</script>
<style>
th span {
  color: "#FF0000" !important;
  border: 0px solid #7181b8;
  border-radius: 44px;
  padding: 5px;
  background: #e8eaf6;
}
#cardborde {
  border-top: 5px solid #e8eaf6 !important;
}
</style>
